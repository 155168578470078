import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import NewsletterBanner from "../../components/newsletterBanner";

import backgroundStillJpg from "../../images/hero_banner.jpg";
import { stringToSlug } from "../../utils/stringManipulation";

const query = graphql`
  query {
    allStrapiPage(
      filter: { sezione: { in: ["blog", null] } }
      sort: { fields: publication_date, order: DESC }
    ) {
      nodes {
        id
        Titolo
        Permalink
        publication_date
        Copertina {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <SEO title="Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper overflow-hidden relative">
        <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>
        <video
          playsInline
          autoPlay
          muted
          loop
          id="bgvid"
          poster={backgroundStillJpg}
          className="absolute home-page-video-height w-screen object-cover object-center"
        >
          <source
            media="(min-width:1280px)"
            src="https://res.cloudinary.com/mohststudio/video/upload/v1619707443/Sterea_Medical_travel_senza_watermark_FINAL_DESKTOP_1_do0jy5.mp4"
            type="video/mp4"
          />
          <source
            src="https://res.cloudinary.com/mohststudio/video/upload/v1619707443/Sterea_Medical_travel_senza_watermark_FINAL_DESKTOP_1_do0jy5.mp4"
            type="video/mp4"
          />
        </video>
        <div className="sterea-center-column home-page-video-height z-20 flex justify-center lg:justify-end items-center">
          <h1 className="w-1/2 text-white text-center lg:text-left lg:leading-tight text-xl lg:text-5xl font-bold">
            Gli articoli delle nostre esperte
          </h1>
        </div>
      </div>

      {/* <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column flex flex-wrap justify-center py-12 px-min -mb-3">
          <div className="sterea-blue-button mr-3 mb-3">
            <Link to="/blog/chirurgia-estetica">
              Chirurgia estetica
            </Link>
          </div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Trapianto capelli</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Fertilità</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Odontoiatria</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Turismo medico</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mb-3">Generale</div>
        </div>
      </div> */}

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column flex flex-wrap justify-between py-lg px-min">
          {data.allStrapiPage.nodes.map((post) => {
            return (
              Date.parse(post.publication_date) <= new Date() && (
                <div className="w-full lg:w-1/2 py-md px-md">
                  <Link
                    to={`/post/${
                      (post.Permalink &&
                        post.Permalink != " " &&
                        post.Permalink) ||
                      stringToSlug(post.Titolo)
                    }`}
                  >
                    <div className="flex justify-start items-end overflow-hidden relative z-30 h-96 rounded-lg">
                      <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>
                      <GatsbyImage
                        image={getImage(post.Copertina?.localFile)}
                        style={{ position: "absolute" }}
                        className="w-full h-full z-10"
                        objectFit="cover"
                        alt=""
                      />
                      <div className="p-4 relative z-20">
                        <h3 className="font-bold text-white">{post.Titolo}</h3>
                        <p className="text-sterea-bright-blue">
                          Leggi l'articolo
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            );
          })}
        </div>
      </div>

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
